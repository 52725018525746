import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Chapter } from '@model/chapter';
import { ContentItem } from '@model/content-item';
import { ContentService } from '@service/content.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContentItemResolver {
  private contentService = inject(ContentService);

  resolve(route: ActivatedRouteSnapshot): Observable<ContentItem | Chapter> {
    const uuid = route.paramMap.get('uuid');
    // TODO check of dit daadwerkelijk voor chapters wordt gebruikt
    const item = this.contentService.getChapterByUuid(uuid) ?? this.contentService.getContentItemByUuid(uuid);

    if (item && !(item as ContentItem).body) {
      return this.contentService.fetchContentItemWithBody(item as ContentItem);
    }

    // Let op, er kunnen natuurlijk content updates zijn. Die worden op deze manier gemist!!
    return of(item);
  }
}
