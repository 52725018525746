import { inject, Injectable } from '@angular/core';
import { AppDataService } from '@services/app-data.service';
import { ContentService } from '@services/content.service';
import { EventService } from '@services/event.service';
import { MenuService } from '@services/menu.service';
import { NetworkService } from '@services/network.service';
import { ParticipantService } from '@services/participant.service';
import { PositionService } from '@services/position.service';
import { PushService } from '@services/push.service';
import { StorageService } from '@services/storage.service';
import { concatMap, Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({ providedIn: 'root' })
export class AppResolver {
  private readonly appDataService = inject(AppDataService);
  private readonly contentService = inject(ContentService);
  private readonly eventService = inject(EventService);
  private readonly menuService = inject(MenuService);
  private readonly networkService = inject(NetworkService);
  private readonly participantService = inject(ParticipantService);
  private readonly positionService = inject(PositionService);
  private readonly pushService = inject(PushService);
  private readonly storageService = inject(StorageService);

  resolve(): Observable<void> {

    return fromPromise(this.storageService.initialize())
      .pipe(
        concatMap(() => this.appDataService.initialize()),
        concatMap(() => this.eventService.initialize()),
        concatMap(() => this.pushService.initialize()),
        concatMap(() => this.networkService.initialize()),
        concatMap(() => this.positionService.initialize()),
        concatMap(() => this.contentService.initialize()),
        concatMap(() => this.menuService.initialize()),
        concatMap(() => this.participantService.initialize()),
      );
  }
}
