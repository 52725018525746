import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Participant } from '@models/participant';
import { ParticipantService } from '@services/participant.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ParticipantResolver {
  private readonly participantService = inject(ParticipantService);
  private readonly router = inject(Router);

  resolve(route: ActivatedRouteSnapshot): Observable<Participant | null> {
    const uuid = route.paramMap.get('uuid');

    return uuid ? this.participantService.get(uuid) : of(null);
  }
}
