import { formatDistance } from "./sk/_lib/formatDistance.js";
import { formatLong } from "./sk/_lib/formatLong.js";
import { formatRelative } from "./sk/_lib/formatRelative.js";
import { localize } from "./sk/_lib/localize.js";
import { match } from "./sk/_lib/match.js";

/**
 * @category Locales
 * @summary Slovak locale.
 * @language Slovak
 * @iso-639-2 slk
 * @author Marek Suscak [@mareksuscak](https://github.com/mareksuscak)
 */
export const sk = {
  code: "sk",
  formatDistance: formatDistance,
  formatLong: formatLong,
  formatRelative: formatRelative,
  localize: localize,
  match: match,
  options: {
    weekStartsOn: 1 /* Monday */,
    firstWeekContainsDate: 4
  }
};

// Fallback for modularized imports:
export default sk;