import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { AppDataService } from '@service/app-data.service';
import { Observable } from 'rxjs';

@Injectable()
export class EventInterceptor implements HttpInterceptor {
  private appDataService = inject(AppDataService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this.appDataService.event() && this.appDataService.event().uuid) {
      const eventUuid = this.appDataService.event().uuid;
      if (eventUuid && request.url.startsWith(environment.url)) {
        request = request.clone({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          setHeaders: { 'X-Event': eventUuid },
        });
      }
    }

    return next.handle(request);
  }
}
