import { Component, inject, OnInit } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Constants } from '@constant/constants';
import { environment } from '@environments/environment';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { setDefaultOptions } from 'date-fns';
import * as Locales from 'date-fns/locale';
import { addIcons } from 'ionicons';
import {
  add,
  alertCircleOutline,
  appsSharp,
  arrowBack,
  arrowForward,
  bicycle,
  calendar,
  calendarSharp,
  call,
  callSharp,
  checkmarkDone,
  chevronForwardCircle,
  close,
  closeCircle,
  ellipsisVerticalCircle,
  eye,
  globe,
  heart,
  heartDislike,
  heartOutline,
  helpCircleOutline,
  home,
  imagesSharp,
  informationCircleSharp,
  link,
  list,
  listSharp,
  locate,
  location,
  logoFacebook,
  logoFlickr,
  logoInstagram,
  logoLinkedin,
  logoWhatsapp,
  logoYoutube,
  mail,
  mailOpen,
  mailUnread,
  megaphone,
  newspaperSharp,
  notificationsSharp,
  people,
  person,
  radio,
  refresh,
  searchSharp,
  settingsSharp,
  shareSocialOutline,
  trailSign,
  trash,
  trashOutline,
  tvOutline,
} from 'ionicons/icons';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'ad6-root',
  templateUrl: 'app.component.html',
  imports: [
    IonApp,
    IonRouterOutlet,
  ],
})
export class AppComponent implements OnInit {
  private readonly translateService = inject(TranslateService);

  constructor() {
    addIcons({
      about: informationCircleSharp,
      add,
      alertCircleOutline,
      bicycle,
      call,
      close,
      closeCircle,
      eye,
      globe,
      location,
      mail,
      heart,
      heartDislike,
      heartOutline,
      helpCircleOutline,
      home,
      shareSocialOutline,
      logoFacebook,
      logoFlickr,
      logoInstagram,
      logoLinkedin,
      logoWhatsapp,
      logoYoutube,
      person,
      refresh,
      locate,
      albums: imagesSharp,
      privacy: eye,
      nieuws: listSharp,
      notifications: notificationsSharp,
      search: searchSharp,
      settings: settingsSharp,
      goNext: chevronForwardCircle,
      mailOpen,
      mailUnread,
      more: ellipsisVerticalCircle,
      radio,
      trash,
      trashOutline,
      tv: tvOutline,
      team: people,
      participants: people,
      arrowBack,
      arrowForward,
      list,
      link,

      // Content icons used in the menu:
      alpedhuzes: calendarSharp,
      alpedhuzus: calendar,
      telefoonnummers: callSharp,
      weekschema: appsSharp,
      blijfopdehoogte: megaphone,
      voorbereiding: checkmarkDone,
      watvindjewaar: trailSign,
      magazine: newspaperSharp,
      url: link,
    });
  }

  ngOnInit(): void {
    // Set and use the default language code for translations
    this.translateService.setDefaultLang(Constants.languageCode);
    this.translateService.use(Constants.languageCode);

    // Set the locale for Moment.js to the default language code

    setDefaultOptions({ locale: Locales[Constants.languageCode] });

    SplashScreen.hide();

    if (environment.name === 'TEST' && environment.description) {
      console.log(`${environment.name}.environment.description=${environment.description}`);
    }
  }
}
