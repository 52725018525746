import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Participant } from '@models/participant';
import { ParticipantService } from '@services/participant.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ParticipantResolver {
  private readonly participantService = inject(ParticipantService);
  private readonly router = inject(Router);

  resolve(route: ActivatedRouteSnapshot): Observable<Participant> {
    const uuid = route.paramMap.get('uuid');
    const participant = this.router.getCurrentNavigation().extras.state?.participant;

    return participant ? of(participant) : this.participantService.get(uuid);
  }
}
