import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Chapter } from '@models/chapter';
import { ContentItem } from '@models/content-item';
import { ContentService } from '@services/content.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContentItemResolver {
  private readonly contentService = inject(ContentService);

  resolve(route: ActivatedRouteSnapshot): Observable<ContentItem | Chapter | null> {
    const uuid = route.paramMap.get('uuid');

    if (!uuid) {
      return of(null);
    }

    // TODO check of dit daadwerkelijk voor chapters wordt gebruikt
    const item = this.contentService.getChapterByUuid(uuid) ?? this.contentService.getContentItemByUuid(uuid);

    if (item instanceof ContentItem) {
      return item.body ? of(item) : this.contentService.fetchContentItemWithBody(item);
    }

    return of(item);
  }
}
