import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import LocaleNl from '@angular/common/locales/nl';
import { enableProdMode, LOCALE_ID, importProvidersFrom, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, provideRouter, withComponentInputBinding } from '@angular/router';
import { environment } from '@environments/environment';
import { EventInterceptor } from '@interceptors/event.interceptor';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { IonicRouteStrategy } from '@ionic/angular/standalone';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import * as Sentry from '@sentry/angular-ivy';
import { register as registerSwiperElements } from 'swiper/element/bundle';

import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';

registerLocaleData(LocaleNl);

export const httpLoaderFactory = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, './assets/i18n/', '.json');

if (environment.production) {
  // Init Sentry, only in production to prevent clutter in Sentry
  // Sentry.init({
  //   dsn: 'https://7944fdd452c743a4a70747b16a882cc1@sentry.flusso.nl/17',
  //   environment: environment.production ? 'production' : 'dev',
  //   integrations: [
  //     new Sentry.BrowserTracing({
  //       tracingOrigins: environment.sentry.tracing.tracingOrigins,
  //       routingInstrumentation: Sentry.routingInstrumentation,
  //     }),
  //   ],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // Sentry recommends adjusting this value in production
  //   tracesSampleRate: environment.production ? 0.2 : 1,
  // });

  enableProdMode();
  if (window) {

    window.console.log = () => { };

    window.console.warn = () => { };
  }

}
registerSwiperElements();

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    {
      provide: RouteReuseStrategy, useClass: IonicRouteStrategy,
    },
    {
      provide: LOCALE_ID, useValue: 'nl-NL',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EventInterceptor,
      multi: true,
    },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes, withComponentInputBinding()),
    provideAnimations(),
    provideExperimentalZonelessChangeDetection(),
    provideIonicAngular({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      innerHTMLTemplatesEnabled: true,
    }),
  ],
})
  .catch(err => console.log(err));
