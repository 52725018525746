import { formatDistance } from "./ar/_lib/formatDistance.js";
import { formatLong } from "./ar/_lib/formatLong.js";
import { formatRelative } from "./ar/_lib/formatRelative.js";
import { localize } from "./ar/_lib/localize.js";
import { match } from "./ar/_lib/match.js";

/**
 * @category Locales
 * @summary Arabic locale (Modern Standard Arabic - Al-fussha).
 * @language Modern Standard Arabic
 * @iso-639-2 ara
 * @author Abdallah Hassan [@AbdallahAHO](https://github.com/AbdallahAHO)
 * @author Koussay Haj Kacem [@essana3](https://github.com/essana3)
 */
export const ar = {
  code: "ar",
  formatDistance: formatDistance,
  formatLong: formatLong,
  formatRelative: formatRelative,
  localize: localize,
  match: match,
  options: {
    weekStartsOn: 6 /* Saturday */,
    firstWeekContainsDate: 1
  }
};

// Fallback for modularized imports:
export default ar;