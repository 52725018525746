import { Injectable, signal } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';

@Injectable({ providedIn: 'root' })
export class NetworkService {

  status = signal<ConnectionStatus | null>(null);

  async initialize(): Promise<void> {
    this.status.set(await Network.getStatus());

    Network.addListener('networkStatusChange', status => {
      this.status.set(status);
    });
    console.log('Initialized NetworkService');
  }

  isConnected(): boolean {
    return this.status()!.connected;
  }
}
